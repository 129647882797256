@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Libre Franklin';
}

.accent {
  font-family: 'Fraunces';
  font-weight: 900;
}

.another-accent {
  font-family: 'Bebas Neue';
}
